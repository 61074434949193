import React from 'react'
import PropTypes from 'prop-types'
import Tree from 'elements/Typography/Tree'
import {
  Heading,
  Flex,
  Box,
  Button
} from '@chakra-ui/core'

export default function Element ({ type, properties, dragHandle, doEdit, doDelete }) {
  return <Flex width={400} bg="gray.200" borderRadius={5} mb={2} p={2}>
    <Flex flex="1" justifyContent="center" flexDirection="column">
      <Heading size="sm">{type}</Heading>
      <Box flex="1" ><Tree data={properties}/></Box>
    </Flex>
    <Flex width="50px" justifyContent="flex-start" flexDirection="column">
      <Button w="100%" mb={2} variant="solid" variantColor="teal" size="xs" mr={2} onClick={doEdit}>Edit</Button>
      <Button w="100%" variant="solid" variantColor="teal" size="xs" onClick={doDelete}>Delete</Button>
    </Flex>
    <Flex flexDirection="row" alignItems="center" justifyContent="flex-end" width="40px">{dragHandle}</Flex>
  </Flex>
}

Element.propTypes = {
  doDelete: PropTypes.func,
  type: PropTypes.string,
  doEdit: PropTypes.func,
  dragHandle: PropTypes.func,
  properties: PropTypes.object
}
