import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Title from 'elements/Typography/Title'
import DataTable from 'elements/DataTable/DataTable'
import useAuthQuery from 'hooks/useAuthQuery'
import useAuthMutation from 'hooks/useAuthMutation'
import CellLabel from 'elements/DataTable/CellLabel'
import CellStat from 'elements/DataTable/CellStat'
import DeleteConfirmation from 'elements/Alert/DeleteConfirmation'
import merge from 'deepmerge'

import {
  Text
} from '@chakra-ui/core'
import MoreButton from 'elements/Button/MoreButton'
import { DOCS_ROUTE } from 'config/routes'
import { DELETE_ITEM, GET_ITEMS } from 'config/queries'

const cols = [
  {
    Header: 'Label',
    accessor: 'status',
    Cell: CellLabel,
    cellProps: { flex: 1 }
  },
  {
    Header: 'Usage',
    accessor: 'type',
    Cell: CellStat,
    cellProps: { width: 200, justifyContent: 'flex-end' }
  }
]

const filterData = data => {
  if (data) {
    return data.logs.nodes.map(node => {
      const { id, status, type } = node
      return { id, status, type }
    })
  }
}

function LogListPage ({ workflowId, serviceId }) {
  const [nextToken, setNextToken] = useState(null)

  const columns = useMemo(() => cols, [])
  const actions = [{
  //   icon: 'edit',
  //   status: 'Edit',
  //   action: (itemId) => {
  //     navigate(setParams(ITEM_UPDATE_ROUTE, { itemId, serviceId }))
  //   }
  // }, {
    icon: 'delete',
    label: 'Delete',
    action: (logId) => {
      confirmDelete(logId)
    }
  }]

  const [deleteItem] = useAuthMutation(DELETE_ITEM, {
    update (cache, { data: { deleteItem } }) {
      const { logs } = cache.readQuery({
        query: GET_ITEMS,
        variables: {
          workflowId
        }
      })
      cache.writeQuery({
        query: GET_ITEMS,
        variables: {
          workflowId
        },
        data: { logs: { ...logs, nodes: logs.nodes.filter(w => w.id !== deleteItem.id) } }
      })
    }
  })

  const { loading: queryLoading, error: queryError, data, fetchMore } = useAuthQuery(GET_ITEMS, {
    variables: {
      // limit: options.dataTablePageSize
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setNextToken(data.logs.nextToken)
    }
  })

  const [open, setOpen] = useState(false)
  const [goner, setGoner] = useState(false)

  const confirmDelete = useCallback(
    (id) => {
      setGoner(id)
      setOpen(true)
    },
    []
  )

  const doDelete = useCallback(
    () => {
      setOpen(false)
      deleteItem({
        variables: {
          input: { id: goner }
        }

      })
    },
    [deleteItem, goner, setOpen]
  )

  const fetchNext = useCallback(() => {
    fetchMore({
      variables: {
        limit: 20,
        after: nextToken
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setNextToken(fetchMoreResult.logs.nextToken)
        if (!fetchMoreResult) return prev
        return merge(prev, fetchMoreResult)
      }
    })
  }, [fetchMore, nextToken])
  console.log(data)
  return (
    <>
      <Title>Log entries</Title>

      { data
        ? data.logs.nodes.length
          ? <DataTable
            isLoading={queryLoading}
            isError={!!queryError}
            fetchMore={fetchNext}
            hasMore={!!nextToken}
            dataHeaders={columns}
            dataItems={filterData(data)}
            rowActions={actions}
          />
          : <Text>Quis aliqua est ipsum mollit eu. Culpa exercitation dolore mollit proident dolore ipsum eu irure. Lorem labore elit minim amet laboris id. Ad reprehenderit magna dolor laborum Lorem dolore laboris aliqua non reprehenderit non ipsum voluptate. <MoreButton to={DOCS_ROUTE}>Learn how to search logs</MoreButton></Text>
        : null }
      <DeleteConfirmation title="Really delete item?" onConfirm={() => doDelete()} onCancel={() => setOpen(false)} isOpen={ open}/>
    </>
  )
}

LogListPage.propTypes = {
  workflowId: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired
}

export default LogListPage
