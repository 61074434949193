import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Form from 'elements/Form/Form'
import SubmitButton from 'elements/Button/SubmitButton'
import { useForm } from 'react-hook-form'
import { uid } from 'react-uid'
import {
  IconButton,
  Box,
  Input,
  FormHelperText,
  FormErrorMessage,
  Stack,
  FormControl,
  FormLabel
} from '@chakra-ui/core'

export default function ElementInput ({ values, doSubmit, doClose, options }) {
  const { handleSubmit, reset, errors, register, formState } = useForm()

  // Builder juts passes new props if form is already opened and a new element selected so we need to reset
  useEffect(() => {
    reset(values)
  }, [reset, values])

  const onSubmit = useCallback(
    (values) => {
      doClose()
      doSubmit(values)
    },
    [doClose, doSubmit]
  )

  const { fields } = options
  return (<Box>
    <IconButton size="xs" icon="close" onClick={doClose}/>
    <Form autoComplete="off" onSubmit={handleSubmit(onSubmit) }>
      <Stack maxWidth={600} spacing={10}>
        { (fields || []).map(field => {
          const { type, label, required } = field

          const params = {}
          if (required) params.required = 'required field'

          switch (type) {
            case 'text':
              return <FormControl key={uid(label)} idisInvalid={!!errors.label}>
                <FormLabel htmlFor="label">{label}</FormLabel>
                <Input
                  variant="filled"
                  name="label"
                  aria-describedby="label-helper-text"
                  ref={register(params)}
                />
                <FormHelperText id="label-helper-text">
            Just something simple so you can find it in the list of workflows
                </FormHelperText>
                <FormErrorMessage>
                  {errors.label && errors.label.message}
                </FormErrorMessage>
              </FormControl>
          }
        })}
        <SubmitButton
          isLoading={formState.isSubmitting}
        >Submit</SubmitButton>
      </Stack>
    </Form>
  </Box>
  )
}

ElementInput.propTypes = {
  values: PropTypes.object,
  doSubmit: PropTypes.func,
  doClose: PropTypes.func,
  options: PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    )
  })

}
