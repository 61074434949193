import React from 'react'
import PropTypes from 'prop-types'
import { setParams } from 'utils/routeUtils'
import Title from 'elements/Typography/Title'
import useAuthMutation from 'hooks/useAuthMutation'
import Link from 'elements/Link/Link'
import SuccessMessage from 'elements/Form/SuccessMessage'
import ErrorMessage from 'elements/Form/ErrorMessage'
import FloatingActionButton from 'elements/Button/FloatingActionButton'
import { addToCache } from 'utils/queryUtils'
import CollectionEditForm from './CollectionEditForm'
import { COLLECTION_LIST_ROUTE } from 'config/routes'
import { ADD_COLLECTION, GET_COLLECTIONS } from 'config/queries'

function CollectionCreatePage ({ serviceId }) {
  const collectionListRoute = setParams(COLLECTION_LIST_ROUTE, { serviceId })

  const [addCollection, { data: mutationData, error: mutationError, loading: mutationLoading }] = useAuthMutation(ADD_COLLECTION)

  const error = mutationError
  const success = !mutationLoading && mutationData && !mutationError

  const onSubmit = async ({ label, definition, summary }) => {
    const input = {
      serviceId,
      label,
      summary,
      definition: JSON.stringify(definition)
    }
    addCollection({
      variables: { input },
      update: addToCache(GET_COLLECTIONS, 'createCollection', 'collections', {
        serviceId
      })
    })
  }

  return (
    <>
      <Title label="Create collection">Create a new collection</Title>
      <FloatingActionButton icon="arrow-back" tooltip="Go to item list" title="Go to collection list" to={collectionListRoute} />
      <CollectionEditForm onSubmit={onSubmit}/>
      { success ? <SuccessMessage message={<Link to={collectionListRoute}>Click here to go to collection list</Link>}/> : null }
      { error ? <ErrorMessage message={error.graphQLErrors[0].message} data={error.graphQLErrors[0].data}/> : null }
    </>
  )
}

CollectionCreatePage.propTypes = {
  serviceId: PropTypes.string.isRequired
}

export default CollectionCreatePage
