import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { setParams } from 'utils/routeUtils'
import Title from 'elements/Typography/Title'
import DataTable from 'elements/DataTable/DataTable'
import useAuthQuery from 'hooks/useAuthQuery'
import useLazyAuthQuery from 'hooks/useLazyAuthQuery'
import useAuthMutation from 'hooks/useAuthMutation'
import FloatingActionButton from 'elements/Button/FloatingActionButton'
import { navigate } from 'gatsby'

import CellLabel from 'elements/DataTable/CellLabel'
import CellStat from 'elements/DataTable/CellStat'
import DetailModal from 'elements/Modal/DetailModal'
import WorkflowDetail from 'views/Workflow/WorkflowDetail'
import DeleteConfirmation from 'elements/Alert/DeleteConfirmation'
import WorkflowGetStarted from './WorkflowGetStarted'
import merge from 'deepmerge'

import {
  useDisclosure
} from '@chakra-ui/core'
import { WORKFLOW_LOGS_ROUTE, WORKFLOW_RUN_ROUTE, WORKFLOW_CREATE_ROUTE, WORKFLOW_UPDATE_ROUTE } from 'config/routes'
import { DELETE_WORKFLOW, GET_WORKFLOWS, GET_WORKFLOW } from 'config/queries'
const cols = [
  {
    Header: 'Name',
    accessor: 'label',
    Cell: CellLabel,
    cellProps: { flex: 1, subComponent: 'summary' }
  },
  {
    Header: 'Usage',
    accessor: 'usage',
    Cell: CellStat,
    cellProps: { width: 200, justifyContent: 'flex-end' }
  }
]

const filterData = data => {
  if (data) {
    return data.workflows.nodes.map(node => {
      const { id, label, summary, usage } = node
      return { id, label, summary, usage: usage.runtime }
    })
  }
}

function WorkflowListPage ({ serviceId }) {
  const [nextToken, setNextToken] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const columns = useMemo(() => cols, [])
  const actions = [
    {
      icon: 'trigger',
      label: 'Trigger',
      action: (workflowId) => {
        navigate(setParams(WORKFLOW_RUN_ROUTE, { workflowId, serviceId }))
      }
    },
    {
      icon: 'view',
      label: 'Detail',
      action: (workflowId) => {
        showDetail(workflowId)
      }
    }, {
      icon: 'edit',
      label: 'Edit',
      action: (workflowId) => {
        navigate(setParams(WORKFLOW_UPDATE_ROUTE, { workflowId, serviceId }))
      }
    }, {
      icon: 'collection',
      label: 'Logs',
      action: (workflowId) => {
        navigate(setParams(WORKFLOW_LOGS_ROUTE, { workflowId, serviceId }))
      }
    }, {
      icon: 'delete',
      label: 'Delete',
      action: (workflowId) => {
        confirmDelete(workflowId)
      }
    }]

  const [deleteWorkflow] = useAuthMutation(DELETE_WORKFLOW, {
    update (cache, { data: { deleteWorkflow } }) {
      const { workflows } = cache.readQuery({
        query: GET_WORKFLOWS,
        variables: {
          serviceId
        }
      })
      cache.writeQuery({
        query: GET_WORKFLOWS,
        variables: {
          serviceId
        },
        data: { workflows: { ...workflows, nodes: workflows.nodes.filter(w => w.id !== deleteWorkflow.id) } }
      })
    }
  })

  //  const [triggerWorkflow] = useAuthMutation(TRIGGER_WORKFLOW)

  const [getWorkflow, { loading: detailLoading, data: detailData }] = useLazyAuthQuery(GET_WORKFLOW)

  const { loading: queryLoading, error: queryError, data, fetchMore } = useAuthQuery(GET_WORKFLOWS, {
    variables: {
      serviceId
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setNextToken(data.workflows.nextToken)
    }
  })

  const [open, setOpen] = useState(false)
  const [goner, setGoner] = useState(false)

  const confirmDelete = useCallback(
    (id) => {
      setGoner(id)
      setOpen(true)
    },
    []
  )

  const doDelete = useCallback(
    () => {
      setOpen(false)
      deleteWorkflow({
        variables: {
          input: { id: goner }
        }

      })
    },
    [deleteWorkflow, goner, setOpen]
  )

  const showDetail = useCallback(
    (id) => {
      onOpen()
      getWorkflow({
        variables: {
          id
        }
      })
    },
    [onOpen, getWorkflow]
  )

  // const doTrigger = useCallback(
  //   (id) => {
  //     triggerWorkflow({
  //       variables: {
  //         input: { id }
  //       }
  //     })
  //   },
  //   [triggerWorkflow]
  // )

  const fetchNext = useCallback(() => {
    fetchMore({
      variables: {
        after: nextToken
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setNextToken(fetchMoreResult.workflows.nextToken)
        if (!fetchMoreResult) return prev
        return merge(prev, fetchMoreResult)
      }
    })
  }, [fetchMore, nextToken])

  return (
    <>
      <Title>Workflows</Title>
      <FloatingActionButton icon="add" tooltip="Add new item" to={setParams(WORKFLOW_CREATE_ROUTE, { serviceId })} />
      { data && data.workflows.nodes.length
        ? <DataTable
          isLoading={queryLoading}
          isError={!!queryError}
          fetchMore={fetchNext}
          hasMore={!!nextToken}
          dataHeaders={columns}
          dataItems={filterData(data)}
          rowActions={actions}
        />

        : null }
      <WorkflowGetStarted/>
      <DeleteConfirmation title="Really delete workflow?" onConfirm={() => doDelete()} onCancel={() => setOpen(false)} isOpen={ open}/>
      <DetailModal onClose={onClose} isOpen={isOpen} title="Workflow details"><WorkflowDetail data={detailLoading ? null : detailData}/></DetailModal>
    </>
  )
}

WorkflowListPage.propTypes = {
  serviceId: PropTypes.string.isRequired
}

export default WorkflowListPage
