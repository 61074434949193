import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Text, Heading } from '@chakra-ui/core'

const components = {
  // eslint-disable-next-line react/display-name
  heading: ({ level, children }) => {
    // Offset md sizes so that we use h2-h6 only
    const sizes = [{ lg: 'h2' }, { md: 'h3' }, { sm: 'h4' }, { xs: 'h5' }, { xs: 'h6' }, { xs: 'h6' }]
    const size = sizes[level]
    return <Heading mb={4} mt={8} size={size} as={sizes[size]}>{children}</Heading>
  }

}

function MarkdownRenderer ({ body }) {
  return (
    <ReactMarkdown source={body} renderers={components}/>
  )
}

MarkdownRenderer.propTypes = {
  body: PropTypes.node
}

export default MarkdownRenderer
