import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage
} from '@chakra-ui/core'

const InputText = forwardRef((props, ref) => {
  const { errors, required, name, help, label, placeholder, options } = props

  return (
    <FormControl display="block" position="relative" isRequired={!!required} isInvalid={!!errors}>
      <FormLabel htmlFor="label">{label}</FormLabel>
      <Input
        ref={ref}
        variant="filled"
        name={name}
        placeholder={placeholder}
        aria-describedby={`${name}-helper-text`}
        // ref={register({ required: 'required field' })}
      />
      {help ? <FormHelperText id={`${name}-helper-text`}>
        {help}
      </FormHelperText> : null }
      <FormErrorMessage>
        {errors && errors.message}
      </FormErrorMessage>
    </FormControl>
  )
})

InputText.displayName = 'InputText'

InputText.defaultProps = {
  required: false
}

InputText.propTypes = {
  errors: PropTypes.object,
  // register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.object
}
export default InputText
