import React from 'react'
import PropTypes from 'prop-types'
import Tree from 'elements/Typography/Tree'
import Loading from 'elements/Loader/Loading'

export default function CollectionDetail ({ data }) {
  return data ? <Tree data={data}/> : <Loading/>
}

CollectionDetail.defaultProps = {
  data: {}
}
CollectionDetail.propTypes = {
  data: PropTypes.object
}
