import React, { forwardRef, useContext, useEffect } from 'react'
import Title from 'elements/Typography/Title'
import Subtitle from 'elements/Typography/Subtitle'
import Header from 'elements/Typography/Header'
import Note from 'elements/Typography/Note'
import ServiceContext from 'contexts/ServiceContext'
import { prettifyDate } from 'utils/dateUtils'
import DetailList from 'elements/Typography/DetailList'
import Loading from 'elements/Loader/Loading'
import SuccessMessage from 'elements/Form/SuccessMessage'
import ErrorMessage from 'elements/Form/ErrorMessage'
import { useTheme, Box, Tabs, TabList, TabPanels, Tab, Icon, TabPanel, Text, Heading } from '@chakra-ui/core'
import ConfigureTab from './ConfigureTab'
import EmptyNotice from 'elements/Notice/EmptyNotice'
import SettingsTab from './SettingsTab'
import MarkdownRenderer from 'elements/Markdown/MarkdownRenderer'
import DynamicForm from 'views/Dynamic/DynamicForm'
import useAuthMutation from 'hooks/useAuthMutation'
import { UPDATE_SERVICE } from 'config/queries'

const SuperTab = forwardRef(({ children, color, ...rest }, ref) => <Tab fontWeight="700" color="gray.500" _selected={{ color: 'gray.900', borderBottom: `2px solid ${color}` }} ref={ref} {...rest}>{children}</Tab>)
SuperTab.displayName = 'SuperTab'

function ServiceOverviewPage () {
  const { service } = useContext(ServiceContext)
  // const [{ service }, dispatch] = useStateValue()
  // useEffect(() => {
  //   setServiceId(serviceId)
  // }, [setServiceId, serviceId])

  const theme = useTheme()

  const [updateService, { data: updateSettingsData, error: updateSettingsError, loading: updateSettingsLoading }] = useAuthMutation(UPDATE_SERVICE)

  const updateSettingsSuccess = !updateSettingsLoading && updateSettingsData && !updateSettingsError

  const onSettingsSubmit = async (values) => {
    const input = {
      id: service.id,
      settings: JSON.stringify(values)
    }
    return updateService({
      variables: { input }
    })
  }

  return service &&

    <>

      <Title label="Overview">{ service.label }</Title>

      <Tabs >
        <TabList>
          <SuperTab color={theme.colors.primary['300']}><Icon name="info" mr={2} />Info</SuperTab>
          <SuperTab color={theme.colors.primary['300']}><Icon name="documentation" mr={2} />Documentation</SuperTab>
          <SuperTab color={theme.colors.primary['300']}><Icon name="gear" mr={2} />Settings</SuperTab>
          <SuperTab color={theme.colors.primary['300']}><Icon name="theme" mr={2} />Theme</SuperTab>
        </TabList>

        <TabPanels>
          <TabPanel position="relative" mt={4}>
            <Header>Details</Header>
            <DetailList w={300} items={{ 'Created on': prettifyDate(service.createdAt), 'Last updated on': prettifyDate(service.updatedAt) }}/>
            <Header>Usage</Header>

            <DetailList w={300} items={{ Runtime: 34, Requests: 67, Storage: 891 }}/>
            <Note>Stats reflect usage for current period</Note>

          </TabPanel>
          <TabPanel position="relative" mt={4}>
            { service.description ? <MarkdownRenderer body={service.description} /> : <EmptyNotice title="No documentation found" message="This service does not provide documentation"/>}
          </TabPanel>
          <TabPanel position="relative" mt={4}>

            { service.settings ? <DynamicForm onSubmit={onSettingsSubmit} elements={service.definition.fields} values={{}}/> : <EmptyNotice title="All set" message="This service does not need to be configured"/> }
            { updateSettingsSuccess ? <SuccessMessage/> : null }
            { updateSettingsError ? <ErrorMessage/> : null }
          </TabPanel>
          <TabPanel position="relative" mt={4}>
            { service.theme ? null : <EmptyNotice title="No theme" message="This service does not provide a UI theme"/> }
          </TabPanel>

        </TabPanels>
      </Tabs>
    </>
}

ServiceOverviewPage.propTypes = {}

export default ServiceOverviewPage
