import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { setParams } from 'utils/routeUtils'
import Title from 'elements/Typography/Title'
import useAuthMutation from 'hooks/useAuthMutation'
import Loading from 'elements/Loader/Loading'
import ErrorMessage from 'elements/Form/ErrorMessage'
import FloatingActionButton from 'elements/Button/FloatingActionButton'
import { Button } from '@chakra-ui/core'
import { WORKFLOW_LIST_ROUTE } from 'config/routes'
import { TRIGGER_WORKFLOW } from 'config/queries'

function WorkflowRunPage ({ workflowId, serviceId }) {
  const workflowListRoute = setParams(WORKFLOW_LIST_ROUTE, { serviceId })
  const [triggerWorkflow, { data, error, loading }] = useAuthMutation(TRIGGER_WORKFLOW)

  const doTrigger = useCallback(
    () => {
      triggerWorkflow({
        variables: { input: { id: workflowId } }
      })
    },
    [triggerWorkflow, workflowId]
  )
  return (
    <>
      <Title label="Trigger workflow">Trigger workflow</Title>
      <FloatingActionButton icon="arrow-back" tooltip="Go to item list" title="Go to workflow list" to={workflowListRoute} />
      <Button
        textTransform="uppercase"
        onClick={doTrigger}
        fontSize="1em"
        variant="solid"
        size="lg"
        variantColor="secondary"
      >
      Trigger Workflow
      </Button>
      { loading ? <Loading/> : null }
      { error ? <ErrorMessage message={error.graphQLErrors[0].message} data={error.graphQLErrors[0].data}/> : null }
      { data ? <>{JSON.stringify(data)}</> : null }
    </>
  )
}

WorkflowRunPage.propTypes = {
  serviceId: PropTypes.string.isRequired
}

export default WorkflowRunPage
