import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { setParams } from 'utils/routeUtils'
import Title from 'elements/Typography/Title'
import DataTable from 'elements/DataTable/DataTable'
import useAuthQuery from 'hooks/useAuthQuery'
import useLazyAuthQuery from 'hooks/useLazyAuthQuery'
import useAuthMutation from 'hooks/useAuthMutation'
import FloatingActionButton from 'elements/Button/FloatingActionButton'
import { navigate } from 'gatsby'
import CellLabel from 'elements/DataTable/CellLabel'
import CellStat from 'elements/DataTable/CellStat'
import DetailModal from 'elements/Modal/DetailModal'
import CollectionDetail from 'views/Collection/CollectionDetail'
import DeleteConfirmation from 'elements/Alert/DeleteConfirmation'
import CollectionGetStarted from './CollectionGetStarted'
import merge from 'deepmerge'

import {
  useDisclosure
} from '@chakra-ui/core'
import { COLLECTION_CREATE_ROUTE, COLLECTION_ITEMS_ROUTE, COLLECTION_UPDATE_ROUTE } from 'config/routes'
import { DELETE_COLLECTION, GET_COLLECTIONS, GET_COLLECTION } from 'config/queries'

const cols = [
  {
    Header: 'Name',
    accessor: 'label',
    Cell: CellLabel,
    cellProps: { flex: 1, subComponent: 'summary' }
  },
  {
    Header: 'Usage',
    accessor: 'usage',
    Cell: CellStat,
    cellProps: { width: 200, justifyContent: 'flex-end' }
  }
]

const filterData = data => {
  if (data) {
    return data.collections.nodes.map(node => {
      const { id, label, summary, usage } = node
      return { id, label, summary, usage: usage.storage }
    })
  }
}

function CollectionListPage ({ serviceId }) {
  const [nextToken, setNextToken] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const columns = useMemo(() => cols, [])
  const actions = [{
    icon: 'view',
    label: 'Detail',
    action: (collectionId) => {
      showDetail(collectionId)
    }
  }, {
    icon: 'collection',
    label: 'Entries',
    action: (collectionId) => {
      navigate(setParams(COLLECTION_ITEMS_ROUTE, { collectionId, serviceId }))
    }
  }, {
    icon: 'edit',
    label: 'Edit',
    action: (collectionId) => {
      navigate(setParams(COLLECTION_UPDATE_ROUTE, { collectionId, serviceId }))
    }
  }, {
    icon: 'delete',
    label: 'Delete',
    action: (collectionId) => {
      confirmDelete(collectionId)
    }
  }]

  const [deleteCollection] = useAuthMutation(DELETE_COLLECTION, {
    update (cache, { data: { deleteCollection } }) {
      const { collections } = cache.readQuery({
        query: GET_COLLECTIONS,
        variables: {
          serviceId
        }
      })
      cache.writeQuery({
        query: GET_COLLECTIONS,
        variables: {
          serviceId
        },
        data: { collections: { ...collections, nodes: collections.nodes.filter(w => w.id !== deleteCollection.id) } }
      })
    }
  })

  const [getCollection, { loading: detailLoading, data: detailData }] = useLazyAuthQuery(GET_COLLECTION)

  const { loading: queryLoading, error: queryError, data, fetchMore } = useAuthQuery(GET_COLLECTIONS, {
    variables: {
      serviceId
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setNextToken(data.collections.nextToken)
    }
  })

  const [confirm, setConfirm] = useState(false)
  const [goner, setGoner] = useState(false)

  const confirmDelete = useCallback(
    (id) => {
      setGoner(id)
      setConfirm(true)
    },
    []
  )

  const showDetail = useCallback(
    (id) => {
      onOpen()
      getCollection({
        variables: {
          id
        }
      })
    },
    [onOpen, getCollection]
  )

  const doDelete = useCallback(
    () => {
      setConfirm(false)
      deleteCollection({
        variables: {
          input: { id: goner }
        }
      })
    },
    [deleteCollection, goner, setConfirm]
  )

  const fetchNext = useCallback(() => {
    fetchMore({
      variables: {
        after: nextToken
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setNextToken(fetchMoreResult.collections.nextToken)
        if (!fetchMoreResult) return prev
        return merge(prev, fetchMoreResult)
      }
    })
  }, [fetchMore, nextToken])

  return (
    <>
      <Title label="Collections">Collections</Title>
      <FloatingActionButton icon="add" tooltip="Add new item" to={setParams(COLLECTION_CREATE_ROUTE, { serviceId })} />
      { data && data.collections.nodes.length
        ? <DataTable
          isLoading={queryLoading}
          isError={!!queryError}
          fetchMore={fetchNext}
          hasMore={!!nextToken}
          dataHeaders={columns}
          dataItems={filterData(data)}
          rowActions={actions}
        />

        : null }
      <CollectionGetStarted/>
      <DeleteConfirmation title="Really delete collection?" onConfirm={() => doDelete()} onCancel={() => setConfirm(false)} isConfirm={ confirm}/>
      <DetailModal onClose={onClose} isOpen={isOpen} title="Collection details"><CollectionDetail data={detailLoading ? null : detailData}/></DetailModal>

    </>
  )
}

CollectionListPage.propTypes = {
  serviceId: PropTypes.string.isRequired
}

export default CollectionListPage
