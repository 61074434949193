import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Router, useMatch } from '@reach/router'
import useLazyAuthQuery from 'hooks/useLazyAuthQuery'
import { gql } from '@apollo/client'
import ServiceFrame from 'elements/Frame/ServiceFrame'
import ServiceContext from 'contexts/ServiceContext'
import ServiceOverviewPage from 'views/Service/ServiceOverviewPage'
import WorkflowListPage from 'views/Workflow/WorkflowListPage'
import WorkflowCreatePage from 'views/Workflow/WorkflowCreatePage'
import WorkflowUpdatePage from 'views/Workflow/WorkflowUpdatePage'
import WorkflowRunPage from 'views/Workflow/WorkflowRunPage'
import CollectionListPage from 'views/Collection/CollectionListPage'
import CollectionCreatePage from 'views/Collection/CollectionCreatePage'
import CollectionUpdatePage from 'views/Collection/CollectionUpdatePage'
import ItemListPage from 'views/Item/ItemListPage'
import LogListPage from 'views/Log/LogListPage'
import ViewListPage from 'views/View/ViewListPage'
import ViewCreatePage from 'views/View/ViewCreatePage'
import ViewUpdatePage from 'views/View/ViewUpdatePage'
import ComingSoonPage from 'views/System/ComingSoonPage'
import ServiceUpdatePage from 'views/Service/ServiceUpdatePage'
// import OverviewPage from 'views/Dashboard/OverviewPage'
import PrivateRoute from 'elements/Routing/PrivateRoute'

import {
  SERVICE_OVERVIEW_ROUTE,
  SERVICE_UPDATE_ROUTE,
  WORKFLOW_LIST_ROUTE,
  WORKFLOW_UPDATE_ROUTE,
  WORKFLOW_CREATE_ROUTE,
  WORKFLOW_RUN_ROUTE,
  // WORKFLOW_LIST_ROUTE,
  WORKFLOW_LOGS_ROUTE,
  VIEW_LIST_ROUTE,
  VIEW_UPDATE_ROUTE,
  VIEW_CREATE_ROUTE,
  COLLECTION_LIST_ROUTE,
  COLLECTION_UPDATE_ROUTE,
  COLLECTION_CREATE_ROUTE,
  COLLECTION_ITEMS_ROUTE
} from 'config/routes'

const GET_SERVICE = gql`
  query Service($id: ID!) {
     service(id: $id) {
       id
        label
        domain
        description
        definition
        settings
        theme
        meta
        createdAt
        updatedAt
      }
  }
`

function ServiceRouteContainer ({ location }) {
  // We wildcard match - this handles any route in the container and lets us extract serviceId easily
  const match = useMatch('/service/:serviceId/*')

  const { service, setService } = useContext(ServiceContext)

  // eslint-disable-next-line no-unused-vars
  const [loadService, { called, loading, data }] = useLazyAuthQuery(
    GET_SERVICE,
    {
      fetchPolicy: 'network-only',
      variables: { id: match.serviceId },
      onCompleted: data => {
        if (data && data.service) {
          setService({
            ...data.service,
            settings: JSON.parse(data.service.settings),
            definition: JSON.parse(data.service.definition),
            meta: JSON.parse(data.service.meta),
            theme: JSON.parse(data.service.theme)
          })
        }
      }
    }
  )
  useEffect(() => {
    if (match.serviceId && (!service || match.serviceId !== service.id)) {
      loadService()
    }
  }, [match.serviceId, service, loadService])

  // useEffect(() => {
  //   // if (match.serviceId !== serviceId) {
  //   setServiceId(match.serviceId)
  // //  }
  // }, [match, serviceId, setServiceId])

  return (
    <ServiceFrame >
      <Router primary={false}>
        <PrivateRoute
          location
          path={SERVICE_OVERVIEW_ROUTE}
          component={ServiceOverviewPage}
        />

        <PrivateRoute
          location
          path={SERVICE_UPDATE_ROUTE}
          component={ServiceUpdatePage}
        />

        <PrivateRoute
          location
          path={WORKFLOW_RUN_ROUTE}
          component={WorkflowRunPage}
        />
        <PrivateRoute
          location
          path={WORKFLOW_CREATE_ROUTE}
          component={WorkflowCreatePage}
        />
        <PrivateRoute
          location
          path={WORKFLOW_UPDATE_ROUTE}
          component={WorkflowUpdatePage}
        />
        <PrivateRoute
          location
          path={WORKFLOW_LIST_ROUTE}
          component={WorkflowListPage}
        />

        <PrivateRoute
          location
          path={COLLECTION_CREATE_ROUTE}
          component={CollectionCreatePage}
        />
        <PrivateRoute
          location
          path={COLLECTION_UPDATE_ROUTE}
          component={CollectionUpdatePage}
        />
        <PrivateRoute
          location
          path={COLLECTION_LIST_ROUTE}
          component={CollectionListPage}
        />

        <PrivateRoute
          location
          path={COLLECTION_ITEMS_ROUTE}
          component={ItemListPage}
        />

        <PrivateRoute
          location
          path={VIEW_LIST_ROUTE}
          component={ViewListPage}
        />

        <PrivateRoute
          location
          path={VIEW_CREATE_ROUTE}
          component={ViewCreatePage}
        />
        <PrivateRoute
          location
          path={VIEW_UPDATE_ROUTE}
          component={ViewUpdatePage}
        />
        <PrivateRoute
          location
          path={WORKFLOW_LOGS_ROUTE}
          component={LogListPage}
        />

        <PrivateRoute location default component={ComingSoonPage} />
      </Router>
    </ServiceFrame>
  )
}

ServiceRouteContainer.propTypes = {

}

export default ServiceRouteContainer
