import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@chakra-ui/core'

function Subtitle ({ children }) {
  return (
    <Heading mb="5" as="h3" size="lg">
      {children}
    </Heading>
  )
}

Subtitle.propTypes = {
  children: PropTypes.node.isRequired
}

export default Subtitle
