import { useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import AuthContext from 'contexts/AuthContext'

// eslint-disable-next-line no-unused-vars
export default function useLazyAuthQuery (query, options = {}) {
  const { accountId } = useContext(AuthContext)
  return useLazyQuery(query, {
    context: {
      headers: { 'x-lotus-account-id': accountId }
    },
    ...options
  })
}
