import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ServiceContext from 'contexts/ServiceContext'
import Layout from 'elements/Frame/Layout'
import ServiceMenu from 'elements/Sidebar/ServiceMenu'

const ServiceFrame = props => {
  const { children } = props
  const { service } = useContext(ServiceContext)

  return (
    <Layout root="dashboard" text={service ? service.label : null} link="/" sidebar={<ServiceMenu/>}>
      {children}
    </Layout>
  )
}

ServiceFrame.propTypes = {
  children: PropTypes.node.isRequired
}

export default ServiceFrame
