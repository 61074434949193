import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@chakra-ui/core'
import { abbreviateNumber } from 'js-abbreviation-number'

export default function CellStat ({ cell }) {
  return <Text fontWeight={700} fontSize="xl">{abbreviateNumber(cell.value, 0)}</Text>
}

CellStat.propTypes = {
  cell: PropTypes.object
}
