import React from 'react'
import MoreButton from 'elements/Button/MoreButton'
import Subtitle from 'elements/Typography/Subtitle'
import {
  Text,
  Divider
} from '@chakra-ui/core'
import { DOCS_ROUTE } from 'config/routes'

export default function ViewGetStarted () {
  return (
    <>
      <Divider my={10}/>
      <Subtitle>Getting started with views</Subtitle>
      <Text>Quis aliqua est ipsum mollit eu. Culpa exercitation dolore mollit proident dolore ipsum eu irure. Lorem labore elit minim amet laboris id. Ad reprehenderit magna dolor laborum Lorem dolore laboris aliqua non reprehenderit non ipsum voluptate. <MoreButton to={DOCS_ROUTE}>Learn how to create views</MoreButton></Text>
    </>
  )
}
