import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import SubmitButton from 'elements/Button/SubmitButton'
import Form from 'elements/Form/Form'
import BuilderFormInput from 'elements/CodeBuilder/BuilderFormInput'
import {
  DragHandle as DefinitionDragHandle,
  ElementInput as DefinitionElementInput,
  Element as DefinitionElement,
  Level as DefinitionLevel,
  toBuilder as definitionToBuilder,
  fromBuilder as definitionFromBuilder,
  schema as definitionSchema,
  visualDocumentationUrl as definitionVisualDocsUrl,
  codeDocumentationUrl as definitionCodeDocsUrl
} from 'builder/collectionDefinition'

import {
  Textarea,
  FormHelperText,
  FormErrorMessage,
  FormControl,
  Stack,
  FormLabel,
  Input
} from '@chakra-ui/core'

export default function CollectionEditForm ({ values, onSubmit }) {
  const { handleSubmit, control, errors, register, formState } = useForm({
    defaultValues: values
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack maxWidth={600} spacing={10}>

        <FormControl isInvalid={!!errors.label}>
          <FormLabel isRequired htmlFor="label">Collection name</FormLabel>
          <Input
            name="label"
            aria-describedby="label-helper-text"
            ref={register({ required: 'required field' })}
          />
          <FormHelperText id="label-helper-text">
            Just something simple so you can find it in the list of collections
          </FormHelperText>
          <FormErrorMessage>
            {errors.label && errors.label.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.summary}>
          <FormLabel htmlFor="summary">Collection summary</FormLabel>
          <Input
            name="summary"
            aria-describedby="summary-helper-text"
            ref={register({ required: false })}
          />
          <FormHelperText id="summary-helper-text">
        Optional short summary (max 100 chars)
          </FormHelperText>
          <FormErrorMessage>
            {errors.summary && errors.summary.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.definition}>
          <FormLabel htmlFor="definition">Collection definition</FormLabel>
          <BuilderFormInput
            name="definition"
            control={control}
            buildSchema={definitionSchema}
            dragHandleComponent={DefinitionDragHandle}
            elementComponent={DefinitionElement}
            levelComponent={DefinitionLevel}
            editorComponent={DefinitionElementInput}
            toBuilder={definitionToBuilder}
            fromBuilder={definitionFromBuilder}
            visualDocumentationUrl={definitionVisualDocsUrl}
            codeDocumentationUrl={definitionCodeDocsUrl}
          />
          <FormHelperText id="definition-helper-text">
            Collection configuration
          </FormHelperText>
          <FormErrorMessage>
            {errors.definition && errors.definition.message}
          </FormErrorMessage>
        </FormControl>

        <SubmitButton
          isLoading={formState.isSubmitting}
        >Submit</SubmitButton>

      </Stack>
    </Form>
  )
}

CollectionEditForm.defaultProps = {
  values: {}
}
CollectionEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object
}
