import React from 'react'
import PropTypes from 'prop-types'
import useAuthMutation from 'hooks/useAuthMutation'
import useAuthQuery from 'hooks/useAuthQuery'
import Loading from 'elements/Loader/Loading'
import SuccessMessage from 'elements/Form/SuccessMessage'
import ErrorMessage from 'elements/Form/ErrorMessage'
import ServiceEditForm from '../Service/ServiceEditForm'
import { UPDATE_SERVICE, GET_SERVICE } from 'config/queries'

function SettingsTab ({ serviceId }) {
//  const serviceListRoute = setParams(SERVICE_LIST_ROUTE, { serviceId })

  const { loading: queryLoading, error: queryError, data: queryData } = useAuthQuery(
    GET_SERVICE,
    { variables: { id: serviceId } }
  )

  const [updateService, { data: mutationData, error: mutationError, loading: mutationLoading }] = useAuthMutation(UPDATE_SERVICE)

  const loading = queryLoading
  const error = queryError || mutationError
  const success = !mutationLoading && mutationData && !mutationError

  const onSubmit = async ({ label, definition, description }) => {
    const input = {
      id: serviceId,
      label,
      description,
      definition: JSON.stringify(definition)

    }
    updateService({
      variables: { input }
    })
  }
  return (
    <>

      { loading ? <Loading/> : null }
      { queryData ? <ServiceEditForm onSubmit={onSubmit} values={queryData.service}/> : null }
      { success ? <SuccessMessage/> : null }
      { error ? <ErrorMessage message={error.graphQLErrors[0].message} data={error.graphQLErrors[0].data}/> : null }
    </>
  )
}

SettingsTab.propTypes = {
  serviceId: PropTypes.string
}

export default SettingsTab
