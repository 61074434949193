import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { uid } from 'react-uid'
import { List, ListItem } from '@chakra-ui/core'

const ListValue = styled('div')`
  display: inline;
  float: right; 
  font-weight: 700;  
`

export default function DetailList ({ items, ...rest }) {
  const keys = Object.keys(items)
  return <List {...rest}>
    {keys.map((key, i) =>
      <ListItem lineHeight="40px" borderBottom={i < keys.length - 1 ? '1px' : 0} borderColor="gray.200" fontSize="sm" key={uid(key)}>{key}: &nbsp;<ListValue>{items[key]}</ListValue></ListItem>
    )}
  </List>
}

DetailList.defaultProps = {
  items: {}
}
DetailList.propTypes = {
  items: PropTypes.object
}
