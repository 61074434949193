import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import SubmitButton from 'elements/Button/SubmitButton'
import Form from 'elements/Form/Form'
import {
  Stack,
  Box
} from '@chakra-ui/core'
import InputText from 'elements/Form/InputText'
const components = {
  text: InputText
  // switch: InputSwitch,
  // choice: InputChoice
}

export default function DynamicForm ({ elements, values, onSubmit }) {
  const { handleSubmit, errors, register, formState } = useForm({
    defaultValues: values
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack maxWidth={600} spacing={10}>
        {elements.map(e => {
          const { type, name, ...rest } = e // type, key, help, placeholder, label , options

          const Component = components[type]
          return <Box key={name} ><Component errors={errors[name]} name={name} ref={register({ required: 'required field' })} {...rest} /></Box>
        })}

        <SubmitButton
          isLoading={formState.isSubmitting}
        >Submit</SubmitButton>

      </Stack>
    </Form>
  )
}

DynamicForm.defaultProps = {
  values: {},
  elements: []
}
DynamicForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
  elements: PropTypes.array
}
