import React from 'react'
import PropTypes from 'prop-types'
import {
  Box
} from '@chakra-ui/core'

export default function Level ({ actions, items, depth }) {
  return (<Box ml={depth * 10}>
    {/* { actions.length ? <BuilderMenu actions={actions}/> : null } */}
    {items}
  </Box>)
}
Level.propTypes = {
  actions: PropTypes.array,
  items: PropTypes.array,
  depth: PropTypes.number
}
