import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@chakra-ui/core'

export default function Note ({ children }) {
  return <Text mt={2} fontStyle="italic" fontSize="xs">{children}</Text>
}

Note.propTypes = {
  children: PropTypes.node
}
