import React from 'react'
import PropTypes from 'prop-types'
import { setParams } from 'utils/routeUtils'
import Title from 'elements/Typography/Title'
import useAuthMutation from 'hooks/useAuthMutation'
import Link from 'elements/Link/Link'
import SuccessMessage from 'elements/Form/SuccessMessage'
import ErrorMessage from 'elements/Form/ErrorMessage'
import FloatingActionButton from 'elements/Button/FloatingActionButton'
import { addToCache } from 'utils/queryUtils'
import ViewEditForm from './ViewEditForm'
import { VIEW_LIST_ROUTE } from 'config/routes'
import { ADD_VIEW, GET_VIEWS } from 'config/queries'

function ViewCreatePage ({ serviceId }) {
  const viewListRoute = setParams(VIEW_LIST_ROUTE, { serviceId })

  const [addView, { data: mutationData, error: mutationError, loading: mutationLoading }] = useAuthMutation(ADD_VIEW)

  const error = mutationError
  const success = !mutationLoading && mutationData && !mutationError

  const onSubmit = async ({ label, alias, definition, summary }) => {
    const input = {
      serviceId,
      label,
      alias,
      summary,
      definition: JSON.stringify(definition)
    }
    addView({
      variables: { input },
      update: addToCache(GET_VIEWS, 'createView', 'views', {
        serviceId
      })

      // update: (cache, { data: { addView } }) => {
      //   let payload
      //   try {
      //     const { workflows } = cache.readQuery({
      //       query: GET_VIEWS,
      //       variables: {
      //         serviceId
      //       }
      //     })
      //     payload = { workflows: { ...workflows, nodes: [...workflows.nodes, addView] } }
      //     cache.writeQuery({
      //       query: GET_VIEWS,
      //       variables: {
      //         serviceId
      //       },
      //       data: payload
      //     })
      //   } catch (e) {
      //     // This happens if no cache (page reloaded for ex)
      //   }
      // }
    })
  }

  return (
    <>
      <Title label="Create view">Create a new view</Title>
      <FloatingActionButton icon="arrow-back" tooltip="Go to item list" title="Go to view list" to={viewListRoute} />
      <ViewEditForm onSubmit={onSubmit}/>
      { success ? <SuccessMessage message={<Link to={viewListRoute}>Click here to go to view list</Link>}/> : null }
      { error ? <ErrorMessage message={error.graphQLErrors[0].message} data={error.graphQLErrors[0].data}/> : null }
    </>
  )
}

ViewCreatePage.propTypes = {
  serviceId: PropTypes.string.isRequired
}

export default ViewCreatePage
