import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { setParams } from 'utils/routeUtils'
import Title from 'elements/Typography/Title'
import useAuthMutation from 'hooks/useAuthMutation'
import Link from 'elements/Link/Link'
import SuccessMessage from 'elements/Form/SuccessMessage'
import ErrorMessage from 'elements/Form/ErrorMessage'
import GraphQlErrorMessage from 'elements/Form/GraphQlErrorMessage'
import FloatingActionButton from 'elements/Button/FloatingActionButton'
import { addToCache } from 'utils/queryUtils'
import WorkflowEditForm from './WorkflowEditForm'
import { WORKFLOW_LIST_ROUTE } from 'config/routes'
import { ADD_WORKFLOW, GET_WORKFLOWS } from 'config/queries'

function WorkflowCreatePage ({ serviceId }) {
  const workflowListRoute = setParams(WORKFLOW_LIST_ROUTE, { serviceId })

  const [addWorkflow, { data: mutationData, error: mutationError, loading: mutationLoading }] = useAuthMutation(ADD_WORKFLOW)

  const error = mutationError
  const success = !mutationLoading && mutationData && !mutationError

  const onSubmit = async ({ label, definition, summary }) => {
    const input = {
      serviceId,
      label,
      summary,
      definition
    }
    addWorkflow({
      variables: { input },
      update: addToCache(GET_WORKFLOWS, 'createWorkflow', 'workflows', {
        serviceId
      })
    }).catch(_ => {})
  }

  return (
    <>
      <Title label="Create workflow">Create a new workflow</Title>
      <FloatingActionButton icon="arrow-back" tooltip="Go to item list" title="Go to workflow list" to={workflowListRoute} />
      <WorkflowEditForm onSubmit={onSubmit}/>
      { success ? <SuccessMessage message={<Link to={workflowListRoute}>Click here to go to workflow list</Link>}/> : null }
      { error ? error.graphQLErrors ? <GraphQlErrorMessage errors={error.graphQLErrors}/> : <ErrorMessage/> : null }
    </>
  )
}

WorkflowCreatePage.propTypes = {
  serviceId: PropTypes.string.isRequired
}

export default WorkflowCreatePage
