import React from 'react'
import PropTypes from 'prop-types'

import {
  SlideIn,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/core'

export default function DetailModal ({ children, title, isOpen, onClose }) {
  return <SlideIn in={isOpen}>
    {styles => (
      <Modal size="full" onClose={onClose} isOpen={true}>
        <ModalOverlay opacity={styles.opacity} />
        <ModalContent w={['100%', '100%', '100%', '80%']} pb={5} {...styles}>
          { title ? <ModalHeader>{title}</ModalHeader> : null}
          <ModalCloseButton />
          <ModalBody>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    )}
  </SlideIn>
}

DetailModal.defaultProps = {
  data: {}
}
DetailModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}
