import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { setParams } from 'utils/routeUtils'
import Title from 'elements/Typography/Title'
import DataTable from 'elements/DataTable/DataTable'
import useAuthQuery from 'hooks/useAuthQuery'
import useLazyAuthQuery from 'hooks/useLazyAuthQuery'
import useAuthMutation from 'hooks/useAuthMutation'
import FloatingActionButton from 'elements/Button/FloatingActionButton'
import { navigate } from 'gatsby'
import CellLabel from 'elements/DataTable/CellLabel'
import CellStat from 'elements/DataTable/CellStat'
import ViewGetStarted from './ViewGetStarted'
import DeleteConfirmation from 'elements/Alert/DeleteConfirmation'
import ServiceContext from 'contexts/ServiceContext'
import merge from 'deepmerge'

import { VIEW_CREATE_ROUTE, VIEW_UPDATE_ROUTE } from 'config/routes'
import { DELETE_VIEW, GET_VIEWS, GET_VIEW } from 'config/queries'

const cols = [
  {
    Header: 'Name',
    accessor: 'label',
    Cell: CellLabel,
    cellProps: { flex: 1, subComponent: 'summary' }
  },
  {
    Header: 'Usage',
    accessor: 'usage',
    Cell: CellStat,
    cellProps: { width: 200, justifyContent: 'flex-end' }
  }
]

const filterData = data => {
  if (data) {
    return data.views.nodes.map(node => {
      const { id, label, summary, usage } = node
      return { id, label, summary, usage: usage.request }
    })
  }
}

function ViewListPage ({ serviceId }) {
  const [nextToken, setNextToken] = useState(null)
  const { service } = useContext(ServiceContext)

  const columns = useMemo(() => cols, [])
  const actions = [{
    icon: 'view',
    label: 'Detail',
    action: (viewId) => {
      showDetail(viewId)
    }
  }, {
    icon: 'view',
    label: 'View',
    action: (viewId) => {
      openView(viewId)
    }
  }, {
    icon: 'edit',
    label: 'Edit',
    action: (viewId) => {
      navigate(setParams(VIEW_UPDATE_ROUTE, { viewId, serviceId }))
    }
  }, {
    icon: 'delete',
    label: 'Delete',
    action: (viewId) => {
      confirmDelete(viewId)
    }
  }]

  const [deleteView] = useAuthMutation(DELETE_VIEW, {
    update (cache, { data: { deleteView } }) {
      const { views } = cache.readQuery({
        query: GET_VIEWS,
        variables: {
          serviceId
        }
      })
      cache.writeQuery({
        query: GET_VIEWS,
        variables: {
          serviceId
        },
        data: { views: { ...views, nodes: views.nodes.filter(w => w.id !== deleteView.id) } }
      })
    }
  })

  const [getView, { called, loading: detailLoading, networkStatus, data: detailData }] = useLazyAuthQuery(GET_VIEW, {
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      console.log('D')
    }
  })

  const { loading: queryLoading, error: queryError, data, fetchMore } = useAuthQuery(GET_VIEWS, {
    variables: {
      serviceId
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setNextToken(data.views.nextToken)
    }
  })

  const [open, setOpen] = useState(false)
  const [goner, setGoner] = useState(false)
  const [current, setCurrent] = useState(false)

  // onCompleted: data => {
  // const url = buildUrl(data)
  // console.log(`Going to ${url}`)

  const confirmDelete = useCallback(
    (id) => {
      setGoner(id)
      setOpen(true)
    },
    []
  )

  const doDelete = useCallback(
    () => {
      setOpen(false)
      deleteView({
        variables: {
          input: { id: goner }
        }

      })
    },
    [deleteView, goner, setOpen]
  )
  useEffect(() => {
    if (detailData) { setCurrent(detailData) }
    // if (!queried || !detailData) return
    // if (!called) {
    //   console.log('Getting from existing')
    //   console.log(detailData)
    // } else {
    //   console.log('Getting new')
    //   console.log(detailData)
    // }
  }, [detailData])

  useEffect(() => {
    if (current) {
      setCurrent(null)
      console.log('Y')
    }
  }, [current])

  const showDetail = useCallback(
    (id) => {
      // const url = haveUrl(id)
      // if (url) {
      //   console.log('cached ' + url)
      // } else {
      getView({
        variables: {
          id
        }

      })
      // }
    },
    [getView]
  )
  const openView = useCallback(
    (id) => {
      const view = data.views.nodes.find(node => id === node.id)

      const url = `https://${service.domain}/${view.alias}`
      window.open(url, '_blank')
    },
    [data, service]
  )
  const fetchNext = useCallback(() => {
    fetchMore({
      variables: {
        after: nextToken
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setNextToken(fetchMoreResult.views.nextToken)
        if (!fetchMoreResult) return prev
        return merge(prev, fetchMoreResult)
      }
    })
  }, [fetchMore, nextToken])

  return (
    <>
      <Title>Views</Title>
      <FloatingActionButton icon="add" tooltip="Add new item" to={setParams(VIEW_CREATE_ROUTE, { serviceId })} />
      { data && data.views.nodes.length
        ? <DataTable
          isLoading={queryLoading}
          isError={!!queryError}
          fetchMore={fetchNext}
          hasMore={!!nextToken}
          dataHeaders={columns}
          dataItems={filterData(data)}
          rowActions={actions}
        />
        : null }
      <ViewGetStarted/>
      <DeleteConfirmation title="Really delete view?" onConfirm={() => doDelete()} onCancel={() => setOpen(false)} isOpen={ open}/>
      {/* <DetailModal onClose={onClose} isOpen={isOpen} title="View details"><ViewDetail data={detailLoading ? null : detailData}/></DetailModal> */}
    </>
  )
}

ViewListPage.propTypes = {
  serviceId: PropTypes.string.isRequired
}

export default ViewListPage
