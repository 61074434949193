import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  List,
  ListItem
} from '@chakra-ui/core'

export default function GraphQLErrorMessage ({ errors }) {
  // For now just show first
  const { message, data } = errors[0]
  return (
    <Alert fontSize="sm" mt={4} maxWidth={600} borderRadius={4} status="error" variant="left-accent">
      <AlertIcon />
      <AlertTitle mr={2}>Error</AlertTitle>
      <AlertDescription>{message}
        { data
          ? <List fontSize="11px">{Object.keys(data).map(k => <ListItem key={k}>{`${k}: ${data[k]}`}</ListItem>)}</List>
          : null }
      </AlertDescription>
    </Alert>
  )
}

GraphQLErrorMessage.propTypes = {
  errors: PropTypes.array
}
