import BuilderMenu from './components/BuilderMenu'
import DragHandle from './components/DragHandle'
import ElementInput from './components/ElementInput'
import Element from './components/Element'
import Level from './components/Level'
const definition = {

  elements: [{
    id: 'TITLE-36007060-8514-11ea-a249-650ef7aeaa3c',
    type: 'title',
    properties: {
      label: 'Foo'
    },
    elements: []
  },
  {
    id: 'DECISION-36007060-8514-11ea-a249-650ef7aeaa3c',
    type: 'decision',
    properties: {
      delay: 10
    },
    elements: []
  },
  {
    id: 'TYPE-c6ae9690-8506-11ea-a249-650ef7aeaa3c',
    type: 'field',
    properties: {
      label: 'Foo'
    },

    elements: [{
      type: 'name',
      id: 'NAME-3424242c9b4d1b0-8506-11ea-a249-650ef7aeaa3c',
      properties: {
        label: 'adass',
        name: {
          first: 'bob',
          last: 'doe'
        }
      },
      elements: []
    }, {
      type: 'name',
      id: 'NAME-2e854900-8974-11ea-a249-650ef7aeaa3c',
      properties: {
        label: 'fooooo'
      },
      elements: []
    }]
  }]
}
const sample = {
  start: 'myWebhook',
  triggers: {
    events: {},
    webhook: {
      active: true,
      keys: ['e3d5e160-ac8a-11e9-8742-0d36cbe54355'],
      origins: ['*']
    }
  },
  steps: {
    firstCondition: {
      type: 'decision',
      description: 'Check for stuff',
      condition: {
        '$.order.amount': {
          $gte: 10
        }
      },
      valid: ['myWebhook', 'someWebhook'],
      invalid: 'sendEmail'
    },
    myWebhook: {
      delay: '10min',
      description: 'Setup webhook for AC',
      type: 'webhook@1.2',
      parameters: {
        allowedOrigins: ['*']
      }
    },
    parallelRuns: {
      type: 'junction',
      description: 'Will wait for all connections',
      next: ['sendEmail', 'sendSMS']
    },
    sendSMS: {
      doNext: 'updateSomething'
    },
    sendEmail: {},
    updateSomething: {},
    storeLead: {
      stepId: 'a263c7c0-9edd-11e9-a17e-61160dbf0776',
      stepDescription: 'Store lead data from webhook in db',
      handlerType: 'query',
      handlerVersion: '>=1.0',
      handlerParams: {
        query: 'store lead data from webhook in db'
      }
    }
  }
}

// Convert to builer format
function toBuilder (data) {
  const workflow = sample
  const steps = (workflow.steps || {})
  const builderSteps = Object.keys(steps).map(name => {
    const def = steps[name]
    return {
      type: def.type,
      properties: {
        description: def.description,
        delay: def.delay
      }
    }
  })
  console.log({ elements: builderSteps })
  return definition
  // return {
  //   elements: builderSteps
  // }
}

// Convert from builer format
function fromBuilder (data) {
  return data
}

const schema = {
  elements: {
    title: {

      label: 'Title',
      count: 1,
      editComponent: ElementInput,
      viewComponent: Element,
      options: {

      }
    },
    field: {
      label: 'Generic Field',
      count: 2,
      editComponent: ElementInput,
      //  view:'ElementView'
      options: {
      },
      elements: {
        name: {

          label: 'Name',
          count: 2,
          // editComponent: ElementInput,
          // viewComponent: ElementView,
          options: {

          }
        }
      }
    },
    echo: {
      label: 'Echo Action',
      count: 2,
      editComponent: ElementInput,
      //  view:'ElementView'
      options: {
        fields: [{
          type: 'text',
          label: 'name',
          required: true
        }, {
          type: 'text',
          label: 'Food'
        }]
      }

    },
    decision: {
      label: 'Decision Action',
      count: 2,
      editComponent: ElementInput,
      //  view:'ElementView'
      options: {
        fields: [{
          type: 'text',
          label: 'name',
          required: true
        }, {
          type: 'text',
          label: 'Food'
        }]
      }

    }
  }
}

const visualDocumentationUrl = 'https://lotusengine.com/docs'
const codeDocumentationUrl = 'https://lotusengine.com/docs'

export {
  BuilderMenu,
  DragHandle,
  ElementInput,
  Element,
  Level,
  toBuilder,
  fromBuilder,
  schema,
  visualDocumentationUrl,
  codeDocumentationUrl

}
