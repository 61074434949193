import React from 'react'
import PropTypes from 'prop-types'
import Tree from 'elements/Typography/Tree'
import Loading from 'elements/Loader/Loading'

export default function WorkflowDetail ({ data }) {
  return data ? <Tree data={data}/> : <Loading/>
}

WorkflowDetail.defaultProps = {
  data: {}
}
WorkflowDetail.propTypes = {
  data: PropTypes.object
}
