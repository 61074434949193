import React, { useEffect, useState, useContext } from 'react'
import { setParams } from 'utils/routeUtils'
import ServiceContext from 'contexts/ServiceContext'
import SideMenu from 'elements/Sidebar/SideMenu'
import {
  SERVICE_OVERVIEW_ROUTE,
  WORKFLOW_LIST_ROUTE,
  VIEW_LIST_ROUTE,
  COLLECTION_LIST_ROUTE
} from 'config/routes'

const dashRoutes = [
  {
    text: 'Overview',
    icon: 'dashboard',
    url: SERVICE_OVERVIEW_ROUTE
  },
  {
    text: 'Workflows',
    icon: 'workflow',
    url: WORKFLOW_LIST_ROUTE
  },
  {
    text: 'Collections',
    icon: 'collection',
    url: COLLECTION_LIST_ROUTE
  },
  {
    text: 'Views',
    icon: 'ui',
    url: VIEW_LIST_ROUTE
  }
]

function ServiceMenu () {
  const { service } = useContext(ServiceContext)
  const [items, setItems] = useState([])
  useEffect(() => {
    if (service) {
      setItems(dashRoutes.map(r => {
        return { ...r, url: setParams(r.url, { serviceId: service.id }), text: r.text }
      }))
    }
    return () => {
      setItems([])
    }
  }, [service])
  return (
    <SideMenu items={items}/>
  )
}

ServiceMenu.propTypes = {

}
export default ServiceMenu
