import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { setParams } from 'utils/routeUtils'
import Title from 'elements/Typography/Title'
import useAuthMutation from 'hooks/useAuthMutation'
import useAuthQuery from 'hooks/useAuthQuery'
import Link from 'elements/Link/Link'
import Loading from 'elements/Loader/Loading'
import SuccessMessage from 'elements/Form/SuccessMessage'
import GraphQlErrorMessage from 'elements/Form/GraphQlErrorMessage'
import ErrorMessage from 'elements/Form/ErrorMessage'
import FloatingActionButton from 'elements/Button/FloatingActionButton'
import WorkflowEditForm from './WorkflowEditForm'
import { updateCache } from 'utils/queryUtils'
import { WORKFLOW_LIST_ROUTE } from 'config/routes'
import { UPDATE_WORKFLOW, GET_WORKFLOW, GET_WORKFLOWS } from 'config/queries'

function WorkflowUpdatePage ({ workflowId, serviceId }) {
  const workflowListRoute = setParams(WORKFLOW_LIST_ROUTE, { serviceId })

  const { loading: queryLoading, error: queryError, data: queryData } = useAuthQuery(
    GET_WORKFLOW,
    { variables: { id: workflowId } }
  )

  const [updateWorkflow, { data: mutationData, error: mutationError, loading: mutationLoading }] = useAuthMutation(UPDATE_WORKFLOW)

  const loading = queryLoading
  const error = queryError || mutationError
  const success = !mutationLoading && mutationData && !mutationError

  const onSubmit = async ({ label, definition, summary }) => {
    const input = {
      id: workflowId,
      label,
      summary,
      definition
    }
    updateWorkflow({
      variables: { input },
      update: updateCache(GET_WORKFLOWS, 'createWorkflow', 'workflows', {
        serviceId
      })
    }).catch(_ => {})
  }
  return (
    <>
      <Title>Edit workflow</Title>
      <FloatingActionButton icon="arrow-back" tooltip="Go to item list" title="Go to workflow list" to={workflowListRoute} />
      { loading ? <Loading/> : null }
      { queryData ? <WorkflowEditForm onSubmit={onSubmit} values={queryData.workflow}/> : null }
      { success ? <SuccessMessage message={<Link to={workflowListRoute}>Click here to go to workflow list</Link>}/> : null }
      { error ? error.graphQLErrors ? <GraphQlErrorMessage errors={error.graphQLErrors}/> : <ErrorMessage/> : null }
    </>
  )
}

WorkflowUpdatePage.propTypes = {
  serviceId: PropTypes.string.isRequired,
  workflowId: PropTypes.string.isRequired
}

export default WorkflowUpdatePage
