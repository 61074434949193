import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Title from 'elements/Typography/Title'
import DataTable from 'elements/DataTable/DataTable'
import useAuthQuery from 'hooks/useAuthQuery'
import useAuthMutation from 'hooks/useAuthMutation'

import CellLabel from 'elements/DataTable/CellLabel'
import DeleteConfirmation from 'elements/Alert/DeleteConfirmation'
import merge from 'deepmerge'

import {
  Text
} from '@chakra-ui/core'
import MoreButton from 'elements/Button/MoreButton'
import { DOCS_ROUTE } from 'config/routes'
import { DELETE_ITEM, GET_ITEMS } from 'config/queries'

const cols = [
  {
    Header: 'Id',
    accessor: 'id',
    Cell: CellLabel,
    cellProps: { flex: 1 }
  }
  // {
  //   Header: 'Usage',
  //   accessor: 'usage',
  //   Cell: CellStat,
  //   cellProps: { width: 200, justifyContent: 'flex-end' }
  // }
]

const filterData = data => {
  if (data) {
    return data.items.nodes.map(node => {
      const { id } = node
      return { id }
    })
  }
}

function ItemListPage ({ collectionId, serviceId }) {
  const [nextToken, setNextToken] = useState(null)

  const columns = useMemo(() => cols, [])
  const actions = [{
  //   icon: EditIcon,
  //   label: 'Edit',
  //   action: (itemId) => {
  //     navigate(setParams(ITEM_UPDATE_ROUTE, { itemId, serviceId }))
  //   }
  // },
  // {
    icon: 'delete',
    label: 'Delete',
    action: (itemId) => {
      confirmDelete(itemId)
    }
  }]

  const [deleteItem] = useAuthMutation(DELETE_ITEM, {
    update (cache, { data: { deleteItem } }) {
      const { items } = cache.readQuery({
        query: GET_ITEMS,
        variables: {
          collectionId
          // limit: options.dataTablePageSize
        }
      })
      cache.writeQuery({
        query: GET_ITEMS,
        variables: {
          collectionId
          // limit: options.dataTablePageSize
        },
        data: { items: { ...items, nodes: items.nodes.filter(w => w.id !== deleteItem.id) } }
      })
    }
  })

  const { loading: queryLoading, error: queryError, data, fetchMore } = useAuthQuery(GET_ITEMS, {
    variables: {
      collectionId
      // limit: options.dataTablePageSize
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      // setNextToken(data.items.nextToken)
    }
  })

  const [open, setOpen] = useState(false)
  const [goner, setGoner] = useState(false)

  const confirmDelete = useCallback(
    (id) => {
      setGoner(id)
      setOpen(true)
    },
    []
  )

  const doDelete = useCallback(
    () => {
      setOpen(false)
      deleteItem({
        variables: {
          input: { id: goner }
        }

      })
    },
    [deleteItem, goner, setOpen]
  )

  const fetchNext = useCallback(() => {
    fetchMore({
      variables: {
        limit: 20,
        after: nextToken
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setNextToken(fetchMoreResult.items.nextToken)
        if (!fetchMoreResult) return prev
        return merge(prev, fetchMoreResult)
      }
    })
  }, [fetchMore, nextToken])

  return (
    <>
      <Title>Items</Title>

      { data
        ? data.items.nodes.length
          ? <DataTable
            isLoading={queryLoading}
            isError={!!queryError}
            fetchMore={fetchNext}
            hasMore={!!nextToken}
            dataHeaders={columns}
            dataItems={filterData(data)}
            rowActions={actions}
          />
          : <Text>Quis aliqua est ipsum mollit eu. Culpa exercitation dolore mollit proident dolore ipsum eu irure. Lorem labore elit minim amet laboris id. Ad reprehenderit magna dolor laborum Lorem dolore laboris aliqua non reprehenderit non ipsum voluptate. <MoreButton to={DOCS_ROUTE}>Learn about collection items</MoreButton></Text>
        : null }
      <DeleteConfirmation title="Really delete item?" onConfirm={() => doDelete()} onCancel={() => setOpen(false)} isOpen={ open}/>
    </>
  )
}

ItemListPage.propTypes = {
  collectionId: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired
}

export default ItemListPage
