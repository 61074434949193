import React from 'react'
import Title from 'elements/Typography/Title'
import { Text } from '@chakra-ui/core'

function ComingSoonPage (props) {
  return (
    <>
      <Title>Coming soon!</Title>
      <Text>This functionality is not yet enabled. Please stay tuned!</Text>
    </>
  )
}

ComingSoonPage.propTypes = {}

export default ComingSoonPage
